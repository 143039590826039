<template>
    <div>
      <FirstSection />
      <div class="mx-2">
        <SecoundSection />
        <Transection />
        <BankTransfer />
      </div>
    </div>
  </template>
  
  <script>
  import FirstSection from "./FirstSection.vue";
  import SecoundSection from "./SecoundSection.vue";
  import Transection from "./Transection.vue";
  import BankTransfer from "./BankTransfer.vue";
  
  export default {
    components: {
      FirstSection,
      SecoundSection,
      Transection,
      BankTransfer,
    },
    setup(props) {
      return {};
    },
  };
  </script>