<template>
  <b-row class="m-0 wallet-transection">
    <b-col cols="12" class="p-0">
      <div class="card mt-3">
        <b-card>
          <b-card-header class="h1">
            <b-card-title class="font-weight-bolder h1"
              >Transection</b-card-title
            >
          </b-card-header>

          <b-table
            sticky-header
            responsive
            class="position-relative b-table-sticky-table"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            sort-direction="asc"
            show-empty
            @sort-changed="sortChanged"
            :per-page="parPage"
            :current-page="currentPage"
            :filter="filter"
            :filter-included-fields="['date', 'amount', 'account', 'status']"
          >
            <!-- Loader -->
            <template #table-busy>
              <div class="position-relative load-data py-2">
                <!-- <loader /> -->
              </div>
            </template>

            <!-- empty data -->
            <template #empty>
              <p class="text-center pt-1">Data Not found.</p>
            </template>

            <!-- Column: deatils -->
            <template #cell(deatils)>
              <b-button
                v-b-modal.modal-no-backdrop
                variant="outline-primary rounded-pill"
              >
                Apply
              </b-button>
            </template>
          </b-table>

          <b-card-body class="d-flex justify-content-end flex-wrap py-0">
            <!-- pagination -->
            <div class="w-100">
              <b-pagination
                v-model="currentPage"
                :total-rows="items.length"
                :per-page="parPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon icon="ArrowLeftIcon" size="18" class="mr-1" /> Previous
                </template>
                <template #next-text>
                  <span>Next</span> <feather-icon icon="ArrowRightIcon" size="18" class="ml-1"/> 
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-card>
      </div>
    </b-col>
  </b-row>
</template>

<script>
/* eslint-disable global-require */
import { computed, ref } from "@vue/composition-api";
import store from "@/store";
export default {
  setup(props, context) {
    const fields = [
      {
        key: "date",
        label: "Date",
        sortable: true,
      },
      {
        key: "amount",
        label: "Amount",
        sortable: true,
      },
      {
        key: "package",
        label: "Package",
        sortable: true,
      },
      {
        key: "client_name",
        label: "client Name",
        sortable: true,
      },
      {
        key: "deatils",
        label: "Deatils",
      },
    ];
    const parPage = 10;
    const currentPage = ref(1);
    const sortByAscDesc = ref("asc");
    const sortBy = ref("");
    const filter = ref("");
    const sortDesc = ref(false);

    const items = [
      {
        id: 1,
        date: "16-03-2023",
        amount: "25467 INR",
        package: "Daily",
        client_name: "Jacob",
      },
      {
        id: 2,
        date: "16-03-2023",
        amount: "25467 INR",
        package: "Daily",
        client_name: "Jacob",
      },
      {
        id: 3,
        date: "16-03-2023",
        amount: "25467 INR",
        package: "Daily",
        client_name: "Jacob",
      },
      {
        id: 4,
        date: "16-03-2023",
        amount: "25467 INR",
        package: "Daily",
        client_name: "Jacob",
      },
    ];
    const sortChanged = (e) => {
      console.log(e.sortBy, "e.sortBy");
      sortBy.value = e.sortBy;
      if (e.sortDesc == true) {
        sortByAscDesc.value = "desc";
      } else {
        sortByAscDesc.value = "asc";
      }
    };

    return {
      sortByAscDesc,
      sortDesc,
      items,
      sortChanged,
      fields,
      filter,
      currentPage,
      parPage,
      sortBy,
    };
  },
};
/* eslint-disable global-require */
</script>